import { FileDownloadResponse } from '@dpdhl-iot/api/backend';

export const createDownloadFileBlob = (content: FileDownloadResponse): Blob => {
  const byteArr = content.fileContent;
  return createDownloadFileString(byteArr);
};

export const createDownloadFileString = (content: string): Blob => {
  const byteCharacters = atob(content);
  const byteArrays = [];
  const sliceSize = 512;
  for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice?.length);
    for (let i = 0; i < slice?.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays);
};
