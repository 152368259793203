import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadImage } from '@dpdhl-iot/predictive-maintenance';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { debounceTime, Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-extended-frequency-diagram',
  templateUrl: './extended-frequency-diagram.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExtendedFrequencyDiagramComponent implements OnInit, OnDestroy {
  @Input() frequencyRangeString: string[] = [];
  @Input() frequencyRangeStringScaled: string[] = [];
  @Input() frequencyRange: number[] = [];
  @Input() frequencyDataCellTimes: number[] = [];
  @Input() loadFrequencyDiagram: Observable<LoadImage>;

  loading = true;
  mouseOverText = '';

  private readonly subscriptions: Subscription[] = [];
  private readonly mouseMoveSubject = new Subject<MouseEvent | undefined>();

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.mouseMoveSubject
        .pipe(debounceTime(100))
        .subscribe((event) => this.overlayDateMouseMove(event)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  mouseMove(event: MouseEvent) {
    this.mouseMoveSubject.next(event);
  }

  overlayDateMouseMove(event?: MouseEvent) {
    this.mouseOverText = '';
    if (!event) {
      return;
    }
    if (event.target instanceof Image && event.target.alt === 'frequency-diagram') {
      // Calculate Date to show underneath the frequency diagram
      this.mouseOverText = this.computeMouseOverText(
        event.offsetX,
        event.offsetY,
        event.target.width,
        event.target.height,
        this.frequencyDataCellTimes,
        this.frequencyRange,
      );
    }
  }

  private computeMouseOverText(
    offsetX: number,
    offsetY: number,
    imageWidth: number,
    imageHeight: number,
    frequencyDataCellTimes: number[],
    frequencyRange: number[],
  ): string {
    let mouseOverText = '';
    const timeOffset = Math.trunc((frequencyDataCellTimes.length * offsetX) / imageWidth);
    const time = frequencyDataCellTimes[timeOffset];
    const timeText = time ? DateTime.fromMillis(time).toFormat('dd.MM.yyyy HH:mm:ss') : '';

    // Calculate frequencies to show underneath the frequency diagram
    const frequencyOffset =
      frequencyRange.length -
      (Math.trunc(((frequencyRange.length - 1) * offsetY) / imageHeight) + 2);
    const frequencyRange1 = frequencyRange[frequencyOffset];
    const frequencyRange2 = frequencyRange[frequencyOffset + 1];
    if (timeText && frequencyRange1 && frequencyRange2) {
      mouseOverText = `${timeText}  -  ${frequencyRange1}-${frequencyRange2} Hz`;
    } else if (timeText && isNaN(frequencyRange1) && isNaN(frequencyRange2)) {
      mouseOverText = `${timeText} - ${this.translateService.instant('predictiveMaintenance.frequencyAnalysis.totalLimitAlert')}`;
    } else if (timeText && isNaN(frequencyRange2)) {
      mouseOverText = `${timeText} - ${this.translateService.instant('predictiveMaintenance.frequencyAnalysis.cellLimitAlert')}`;
    }
    return mouseOverText;
  }

  overlayDateMouseLeave() {
    this.mouseMoveSubject.next(undefined);

    this.mouseOverText = '';
  }
}
