import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { getAlertStatusChipColor, getContextMenusByAlertStatus } from '@dpdhl-iot/alert';
import { DPDHLTable } from '@dpdhl-iot/como-ui';
import {
  AlertActionModel,
  AlertActionType,
  AlertStatusType,
  AlertViewModel,
  DefaultViewConfiguration,
  UpdateAlertStatusRequestArgs,
  ViewConfigurationModel,
} from '@dpdhl-iot/shared';

@Component({
  selector: 'app-alert-list-table',
  templateUrl: './alert-list-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertListTableComponent implements OnChanges {
  @Input() alertData: AlertViewModel[] = [];
  @Input() showAreaLink = false;
  @Input() workFlowFacilities: string[] = [];
  @Input() hasAlertUpdatePermissionFacilities: string[] = [];
  @Input() viewConfiguration: ViewConfigurationModel = DefaultViewConfiguration;
  @Input() isLoading = true;
  @Input() selectableFacilities: string[] = [];
  @Input() alertAction: AlertActionModel | undefined = undefined;
  @Output() selectAlertAction = new EventEmitter<AlertActionModel>();
  @Output() updatedAlertWorkflow = new EventEmitter<UpdateAlertStatusRequestArgs>();
  @Output() closeAlertWorkflow = new EventEmitter();

  AlertActionType = AlertActionType;
  showWorkflowDialog = false;
  activeAlertStatus = ['Open', 'Accepted', 'Resolving'];
  downloadConfig: DPDHLTable.DownloadConfig = {
    fileName: 'AlertList',
  };
  sortingConfig: DPDHLTable.SortingConfig = {
    defaultSortField: 'severity',
    defaultSortDirection: 'ascending',
  };
  valueSortConfig: DPDHLTable.ColumnSortingConfig = {
    sort: (a: string, b: string) => {
      const x = parseInt(a, 10);
      const y = parseInt(b, 10);
      if (isNaN(x) && !isNaN(y)) {
        return 1;
      }
      if (!isNaN(x) && isNaN(y)) {
        return -1;
      }
      if (isNaN(x) && isNaN(y)) {
        return a.localeCompare(b);
      }
      return x - y;
    },
  };
  severitySortConfig: DPDHLTable.ColumnSortingConfig = {
    sort: (a: string, b: string) => {
      const x = this.severitiesMap.get(a) ?? 4;
      const y = this.severitiesMap.get(b) ?? 4;
      return x - y;
    },
  };
  private readonly severitiesMap = new Map<string, number>()
    .set('High', 1)
    .set('Alert', 1)
    .set('Normal', 2)
    .set('Warning', 2)
    .set('Low', 3);

  allowWorkflowAction(alertModel: AlertViewModel) {
    return (
      this.workFlowFacilities.length > 0 ||
      (this.hasAlertUpdatePermission(alertModel) &&
        this.alertData.some((alert) => this.activeAlertStatus.includes(alert.statusId.toString())))
    );
  }
  allowAlertWorkflow(alert: AlertViewModel): boolean {
    return this.allowManuallyClose(alert) || this.allowWorkflowFacility(alert.facilityId);
  }

  allowWorkflowFacility(facilityId?: string): boolean {
    return this.workFlowFacilities.some((facility) => facility === facilityId);
  }

  allowManuallyClose(alert: AlertViewModel): boolean {
    return (
      this.hasAlertUpdatePermission(alert) &&
      this.activeAlertStatus.includes(alert.statusId.toString())
    );
  }

  getAlertStatusChipColor(statusId: AlertStatusType): string {
    return getAlertStatusChipColor(statusId);
  }

  getActions(alertDetail: AlertViewModel): AlertActionType[] {
    let action: AlertActionType[] = [];
    if (this.allowAlertWorkflow(alertDetail)) {
      action = getContextMenusByAlertStatus(alertDetail.statusId);
      if (
        !this.hasAlertUpdatePermission(alertDetail) &&
        this.allowWorkflowFacility(alertDetail.facilityId)
      ) {
        action = action.filter((context) => context !== AlertActionType.Close);
      } else if (
        this.allowManuallyClose(alertDetail) &&
        !this.allowWorkflowFacility(alertDetail.facilityId)
      ) {
        action = [AlertActionType.Close];
      }
    }
    return action;
  }

  hasAlertUpdatePermission(alert: AlertViewModel): boolean {
    return this.hasAlertUpdatePermissionFacilities.some(
      (facility) => facility === alert.facilityId,
    );
  }

  onActionClick(args: AlertActionModel) {
    this.selectAlertAction.emit(args);
    this.showWorkflowDialog = true;
  }

  onUpdateAlertStatus(args: UpdateAlertStatusRequestArgs) {
    this.updatedAlertWorkflow.emit(args);
    this.onCloseAlertStatus();
  }

  onCloseAlertStatus() {
    this.showWorkflowDialog = false;
    this.closeAlertWorkflow.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewConfiguration?.currentValue) {
      this.downloadConfig.toRowEntry = this.viewConfiguration.alert.toRowEntry;
    }
  }
}
