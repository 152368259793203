import { DeviceDetails, FacilityInfo, SubOrg } from '@dpdhl-iot/api/backend';
import { SensorType } from '../services/graph-management/enums/sensor-type.enum';

export const facilityAreaComparer = (a: SubOrg, b: SubOrg): number =>
  b.totalOpenAlertsCount - a.totalOpenAlertsCount || b.activeDeviceCount - a.activeDeviceCount;

export const removeDuplicateBatterySensorFromDevice = (devices: DeviceDetails[]): void => {
  devices.forEach((device) => {
    if (
      device.deviceSensorTypes?.some(
        (sensor) => sensor.deviceSensorType?.toString() === SensorType.Battery,
      ) &&
      device.deviceSensorTypes?.some(
        (sensor) => sensor.deviceSensorType?.toString() === SensorType.SoC,
      )
    ) {
      device.deviceSensorTypes = device.deviceSensorTypes?.filter(
        (sensor) => sensor.deviceSensorType?.toString() !== SensorType.Battery,
      );
    }
  });
};

export const removeDuplicateBatterySensorFromArea = (area: SubOrg): void => {
  if (area.sensors && area.sensors.length > 0) {
    if (
      area.sensors.some((sensor) => sensor.sensorType.toString() === SensorType.Battery) &&
      area.sensors.some((sensor) => sensor.sensorType.toString() === SensorType.SoC)
    ) {
      area.sensors = area.sensors.filter(
        (sensor) => sensor.sensorType.toString() !== SensorType.Battery,
      );
    }
  }
};

export const getSelectableAndWorkflowEnabledFacilities = (
  facilities: FacilityInfo[],
): { selectable: string[]; workflowEnabled: string[]; hasAlertUpdatePermission: string[] } => {
  const workflowEnabled = facilities
    .filter((facility) => facility.isAlertManagementWorkflowEnabled)
    .map((facility) => facility.id);
  const selectable = facilities
    .filter((facility) => facility.isSelectable)
    .map((facility) => facility.id);
  const hasAlertUpdatePermission = facilities
    .filter((facility) => facility.hasAlertUpdatePermission)
    .map((facility) => facility.id);

  return { selectable, workflowEnabled, hasAlertUpdatePermission };
};

export const statusColorClassToCode = (status: StatusColorClass): StatusColorCode => {
  let colorCode: StatusColorCode;
  switch (status) {
    case StatusColorClass.Red:
      colorCode = StatusColorCode.Red;
      break;
    case StatusColorClass.Yellow:
      colorCode = StatusColorCode.Yellow;
      break;
    case StatusColorClass.Green:
      colorCode = StatusColorCode.Green;
      break;
    case StatusColorClass.Grey:
      colorCode = StatusColorCode.Grey;
      break;
    case StatusColorClass.Pink:
      colorCode = StatusColorCode.Pink;
      break;
    default:
      colorCode = StatusColorCode.White;
  }
  return colorCode;
};

export const legendColorClassByKey = (key: StatusColorClass): string => {
  switch (key) {
    case StatusColorClass.Red:
      return 'red-200';
    case StatusColorClass.Yellow:
      return 'yellow-400';
    case StatusColorClass.Green:
      return 'green-400';
    case StatusColorClass.Grey:
      return 'gray-450';
    default:
      return '';
  }
};

export enum StatusColorClass {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Grey = 'grey',
  Pink = 'pink',
  White = 'white',
}

export enum StatusColorCode {
  Red = '#e70000',
  Yellow = '#eaa200',
  Green = '#02903e',
  Grey = '#919191',
  Pink = '#ff939c',
  White = '#fff',
}
