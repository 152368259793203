import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { CarouselOptionDirective } from './carousel-option/carousel-option.directive';

@Component({
  standalone: true,
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @ContentChildren(CarouselOptionDirective)
  items!: QueryList<CarouselOptionDirective>;
  currentIndex = 0;

  nextSlide() {
    this.currentIndex = this.normalizeIndex(this.currentIndex + 1);
  }

  private normalizeIndex(index: number): number {
    if (index < 0) {
      return this.items.length - 1;
    } else if (index >= this.items.length) {
      return 0;
    }
    return index;
  }
}
