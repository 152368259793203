import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyTextValue, TextValuePair } from '@dpdhl-iot/alert-templates';
import {
  AlertFrequency,
  AlertRuleTemplateRule,
  CombinedAlertRuleType,
  CompareValueAlertTriggerConditions,
  DayOfWeek,
  DaysOfWeekAndTimeFilter,
  TimeRangeFilter,
  TimeTriggeredDeviceCountTriggerConditions,
  TriggerOperator,
} from '@dpdhl-iot/api/backend';
import { setHoursMinutes, validateTimeRange } from '@dpdhl-iot/shared';
import { TimePickerService } from '@dpdhl/angular-shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class AlertTemplateFormBuilderService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly timePickerService: TimePickerService,
  ) {}

  createNewRuleFormGroup(alertRule: AlertRuleTemplateRule, isStockTemplate: boolean): FormGroup {
    return isStockTemplate
      ? this.createNewStockRuleFormGroup(alertRule)
      : this.createNewAlertRuleFormGroup(alertRule);
  }

  createRule(formGroup: FormGroup, isStockTemplate: boolean): AlertRuleTemplateRule {
    return isStockTemplate ? this.createStockRule(formGroup) : this.createAlertRule(formGroup);
  }

  createNewRule(isStockTemplate: boolean): AlertRuleTemplateRule {
    return isStockTemplate ? this.createNewStockAlertRule() : this.createNewAlertRule();
  }

  createParameters(isStockTemplate: boolean) {
    return isStockTemplate
      ? [
          this.createParams(
            'timetriggereddevicecount',
            'alertTemplates.availableDevices',
            CombinedAlertRuleType.TIME_TRIGGERED_DEVICE_COUNT,
          ),
        ]
      : [
          this.createParams(
            'temperature',
            'room.chart.temperature',
            'SensorMeasurements.Temperature',
          ),
          this.createParams('humidity', 'room.chart.humidity', 'SensorMeasurements.Humidity'),
          this.createParams(
            'battery',
            'room.chart.batterypercentage',
            'SensorMeasurements.BatteryPercentage',
          ),
          this.createParams(
            'co2',
            'room.chart.carbondioxidelevel',
            'SensorMeasurements.CarbonDioxideLevel',
          ),
          this.createParams(
            'particulatematter1_0',
            'room.chart.particulatematter1_0',
            'Properties.particulateMatterConcentration10',
          ),
          this.createParams(
            'particulatematter2_5',
            'room.chart.particulatmatter2_5',
            'Properties.["particulateMatterConcentration2.5"]',
          ),
          this.createParams(
            'particulatematter10',
            'room.chart.particulatematter10',
            'Properties.["particulateMatterConcentration1.0"]',
          ),
        ];
  }

  createSeverities(): TextValuePair[] {
    return [
      {
        text: this.translateService.instant('alert.severity.low'),
        value: 'Low',
      },
      {
        text: this.translateService.instant('alert.severity.normal'),
        value: 'Normal',
      },
      {
        text: this.translateService.instant('alert.severity.high'),
        value: 'High',
      },
    ];
  }

  createConditions(): TextValuePair[] {
    return this.createValues(TriggerOperator);
  }

  createFrequencies(): TextValuePair[] {
    return this.createValues({
      ONETIME: 'Onetime',
      CONTINUOUSLY: 'Continuously',
    });
  }

  createDaysOfWeek(): TextValuePair[] {
    return this.createValues(DayOfWeek);
  }

  createValues(obj: object) {
    return Object.entries(obj).map((e) => ({
      text: this.translateService.instant('alertTemplates.' + e[0]) ?? e[0],
      value: e[1] as string,
    }));
  }

  private createParams(key: string, text: string, value: string): KeyTextValue {
    return {
      key,
      text: this.translateService.instant(text),
      value,
    };
  }

  private createNewAlertRuleFormGroup(alertRule: AlertRuleTemplateRule): FormGroup {
    return this.formBuilder.group(
      {
        ruleId: new FormControl(alertRule.triggerConditions?.id),
        alertName: new FormControl(alertRule.triggerConditions?.alertName, [Validators.required]),
        severity: new FormControl(alertRule.metaData?.severity ?? '', [Validators.required]),
        parameter: new FormControl(
          (alertRule.triggerConditions as CompareValueAlertTriggerConditions)?.propertyPath,
          [Validators.required],
        ),
        condition: new FormControl(
          (alertRule.triggerConditions as CompareValueAlertTriggerConditions)?.limit,
          [Validators.required],
        ),
        threshold: new FormControl(
          (alertRule.triggerConditions as CompareValueAlertTriggerConditions)?.threshold,
          [Validators.required],
        ),
        frequency: new FormControl(
          (alertRule.triggerConditions as CompareValueAlertTriggerConditions)?.alertFrequency,
          [Validators.required],
        ),
        occurrencesCount: new FormControl(
          (alertRule.triggerConditions as CompareValueAlertTriggerConditions)
            ?.alertOccurrencesCount ?? 0,
          [Validators.required],
        ),
        daysOfWeek: new FormControl(alertRule.daysOfWeekTimeFilter?.daysOfWeek),
        timeFrom: this.createTimePickerForm(alertRule.daysOfWeekTimeFilter?.timeRange, true),
        timeTo: this.createTimePickerForm(alertRule.daysOfWeekTimeFilter?.timeRange, false),
      },
      {
        validators: [(control) => validateTimeRange(control.value.timeFrom, control.value.timeTo)],
      },
    );
  }

  private createNewStockRuleFormGroup(alertRule: AlertRuleTemplateRule): FormGroup {
    return this.formBuilder.group({
      ruleId: new FormControl(alertRule.triggerConditions?.id),
      alertName: new FormControl(alertRule.triggerConditions?.alertName, [Validators.required]),
      severity: new FormControl(alertRule.metaData?.severity, [Validators.required]),
      parameter: new FormControl(alertRule.triggerConditions?.type, [Validators.required]),
      condition: new FormControl(
        (alertRule.triggerConditions as TimeTriggeredDeviceCountTriggerConditions)?.operator,
        [Validators.required],
      ),
      threshold: new FormControl(
        (alertRule.triggerConditions as TimeTriggeredDeviceCountTriggerConditions)?.countThreshold,
        [Validators.required],
      ),
      frequency: new FormControl(
        (alertRule.triggerConditions as TimeTriggeredDeviceCountTriggerConditions)?.alertFrequency,
        [Validators.required],
      ),
      fromThreshold: new FormControl(300),
      daysOfWeek: new FormControl(alertRule.daysOfWeekTimeFilter?.daysOfWeek),
      timeFrom: this.createTimePickerForm(alertRule.daysOfWeekTimeFilter?.timeRange, true),
      timeTo: this.createTimePickerForm(alertRule.daysOfWeekTimeFilter?.timeRange, false),
    });
  }

  private createStockRule(formGroup: FormGroup): AlertRuleTemplateRule {
    const category = formGroup.value.parameter;
    const metaData = { severity: formGroup.value.severity };
    const triggerConditions: TimeTriggeredDeviceCountTriggerConditions = {
      id: formGroup.value.ruleId,
      alertFrequency: formGroup.value.frequency,
      alertName: formGroup.value.alertName,
      type: formGroup.value.parameter,
      operator: formGroup.value.condition,
      countThreshold: formGroup.value.threshold,
      fromThreshold: formGroup.value.fromThreshold,
    };
    const daysOfWeekTimeFilter = this.createDaysOfWeekAndTimeFilter(formGroup.value);

    return {
      category,
      metaData,
      triggerConditions,
      daysOfWeekTimeFilter,
    };
  }

  private createAlertRule(formGroup: FormGroup): AlertRuleTemplateRule {
    const category = formGroup.value.parameter;
    const metaData = { severity: formGroup.value.severity };
    const triggerConditions: CompareValueAlertTriggerConditions = {
      id: formGroup.value.ruleId,
      alertFrequency: formGroup.value.frequency,
      alertName: formGroup.value.alertName,
      type: CombinedAlertRuleType.VALUE_COMPARE,
      limit: formGroup.value.condition,
      propertyPath: formGroup.value.parameter,
      threshold: formGroup.value.threshold,
      alertOccurrencesCount: formGroup.value.occurrencesCount,
    };
    const daysOfWeekTimeFilter = this.createDaysOfWeekAndTimeFilter(formGroup.value);

    return {
      category,
      metaData,
      triggerConditions,
      daysOfWeekTimeFilter,
    };
  }

  private createNewAlertRule(): AlertRuleTemplateRule {
    const metaData = { severity: 'Normal' };
    const triggerConditions: CompareValueAlertTriggerConditions = {
      id: Guid.create().toString(),
      alertFrequency: AlertFrequency.ONETIME,
      alertName: '',
      type: CombinedAlertRuleType.VALUE_COMPARE,
      limit: TriggerOperator.EQUALS,
      propertyPath: '',
      threshold: 0,
      alertOccurrencesCount: 0,
    };

    const daysOfWeekTimeFilter: DaysOfWeekAndTimeFilter = {
      daysOfWeek: undefined,
      timeRange: undefined,
      timezone: undefined,
    };

    return {
      metaData,
      triggerConditions,
      daysOfWeekTimeFilter,
    };
  }

  private createNewStockAlertRule(): AlertRuleTemplateRule {
    const metaData = { severity: 'Normal' };
    const triggerConditions: TimeTriggeredDeviceCountTriggerConditions = {
      id: Guid.create().toString(),
      alertFrequency: AlertFrequency.ONETIME,
      alertName: '',
      type: CombinedAlertRuleType.TIME_TRIGGERED_DEVICE_COUNT,
      operator: TriggerOperator.EQUALS,
      countThreshold: 0,
      fromThreshold: 300,
    };

    const daysOfWeekTimeFilter: DaysOfWeekAndTimeFilter = {
      daysOfWeek: undefined,
      timeRange: undefined,
      timezone: undefined,
    };

    return {
      metaData,
      triggerConditions,
      daysOfWeekTimeFilter,
    };
  }

  private createTimePickerForm(timeRange: TimeRangeFilter | undefined, isStartTime: boolean) {
    const formGroup = this.timePickerService.createForm(false);
    if (timeRange) {
      const startDate = setHoursMinutes(timeRange.startHour, timeRange.startMinute, true);
      const endDate = setHoursMinutes(timeRange.endHour, timeRange.endMinute, true);
      formGroup.patchValue(
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hours: (isStartTime ? startDate.getHours() : endDate.getHours()) ?? null,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          minutes: (isStartTime ? startDate.getMinutes() : endDate.getMinutes()) ?? null,
        },
        { emitEvent: false },
      );
    }
    return formGroup;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createDaysOfWeekAndTimeFilter(value: any) {
    let daysOfWeekTimeFilter: DaysOfWeekAndTimeFilter | undefined;

    const timeValueList = [
      value.timeFrom.hours,
      value.timeFrom.minutes,
      value.timeTo.hours,
      value.timeTo.minutes,
    ];
    const validTimeRange = timeValueList.every((x) => x !== null && x !== undefined);

    if (value.daysOfWeek || validTimeRange) {
      daysOfWeekTimeFilter = {
        daysOfWeek: value.daysOfWeek ?? undefined,
        timeRange: validTimeRange ? this.getTimeRangeValues(timeValueList) : undefined,
        timezone:
          value.daysOfWeek || validTimeRange
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : undefined,
      };
    }
    return daysOfWeekTimeFilter;
  }

  private getTimeRangeValues(timeValueList: number[]): TimeRangeFilter | undefined {
    if (timeValueList.length === 4 && timeValueList.every((x) => !isNaN(x))) {
      const startDate = setHoursMinutes(timeValueList[0], timeValueList[1], false);
      const endDate = setHoursMinutes(timeValueList[2], timeValueList[3], false);

      return {
        startHour: startDate.getUTCHours(),
        startMinute: startDate.getUTCMinutes(),
        endHour: endDate.getUTCHours(),
        endMinute: endDate.getUTCMinutes(),
      };
    } else {
      return undefined;
    }
  }
}
