import { Injectable } from '@angular/core';
import { NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler extends ApplicationinsightsAngularpluginErrorService {
  private readonly invalidErrorMessages = ['BrowserAuthError'];

  constructor(private readonly toastNotificationService: NotificationDataService) {
    super();
  }

  override handleError(error: Error): void {
    super.handleError(error);
    if (!environment.production) {
      if (!this.invalidErrorMessages.some((message) => error.message.includes(message))) {
        this.toastNotificationService.addNotification({
          type: NotificationType.ERROR,
          text: error.message,
        });
      }
      console.error(error);
    }
  }
}
