import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { KeyTextValue, TextValuePair } from '@dpdhl-iot/alert-templates';
import {
  AlertRuleTemplateRule,
  CompareValueAlertTriggerConditions,
  DaysOfWeekAndTimeFilter,
  TimeRangeFilter,
  TimeTriggeredDeviceCountTriggerConditions,
} from '@dpdhl-iot/api/backend';
import { CoreConstants, SensorType, setHoursMinutes } from '@dpdhl-iot/shared';
import { EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { AlertTemplateFormBuilderService } from '../../services/alert-template-form-builder/alert-template-form-builder.service';

@Component({
  selector: 'app-alert-rule-list',
  templateUrl: './alert-rule-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertRuleListComponent implements OnInit {
  @Input() alertRules: AlertRuleTemplateRule[];
  @Input() isInventoryTracking = false;
  @Input() viewOnly = false;
  @Input() enableSearch = true;
  @Input() unitSystem = EnvironmentalUnitSystem.METRIC;
  @Output() editRule = new EventEmitter<AlertRuleTemplateRule>();
  @Output() deleteRule = new EventEmitter<AlertRuleTemplateRule>();

  conditions: TextValuePair[] = [];
  parameters: KeyTextValue[] = [];

  constructor(private readonly templateFormService: AlertTemplateFormBuilderService) {}

  ngOnInit(): void {
    this.conditions = this.templateFormService.createConditions();
    this.parameters = this.templateFormService.createParameters(this.isInventoryTracking);
  }

  getCondition(rule: AlertRuleTemplateRule) {
    if (!rule.category) {
      return '';
    }

    if (this.isInventoryTracking) {
      const triggerConditions = rule.triggerConditions as TimeTriggeredDeviceCountTriggerConditions;
      return `${
        this.parameters.find((p) => p.value === triggerConditions.type)?.text
      } ${this.conditions
        .find((condition) => condition.value === triggerConditions.operator)
        ?.text.toLowerCase()} ${triggerConditions.countThreshold}`;
    } else {
      const triggerConditions = rule.triggerConditions as CompareValueAlertTriggerConditions;
      return `${
        this.parameters.find((p) => p.value === triggerConditions.propertyPath)?.text
      } ${this.conditions
        .find((condition) => condition.value === triggerConditions.limit)
        ?.text.toLowerCase()} ${this.getThreshold(triggerConditions.threshold, rule.category)}`;
    }
  }

  getParameterIcon(category: string) {
    const parameter = this.parameters.find((x) => x.value === category);
    return parameter ? parameter.key : category.toLowerCase();
  }

  getExecuteOn(daysOfWeekTime: DaysOfWeekAndTimeFilter) {
    return `${
      daysOfWeekTime.daysOfWeek?.map((day) => day.toString().substring(0, 3)).join(', ') ?? ''
    } ${this.getTimeRange(daysOfWeekTime.timeRange)}`;
  }

  onEditRule(rule: AlertRuleTemplateRule) {
    this.editRule.emit(rule);
  }

  onRemoveRule(rule: AlertRuleTemplateRule) {
    this.deleteRule.emit(rule);
  }

  private getTimeRange(timeRange: TimeRangeFilter | undefined) {
    if (timeRange && Object.keys(timeRange).every((k) => k)) {
      const startDate = setHoursMinutes(timeRange.startHour ?? 0, timeRange.startMinute ?? 0, true);
      const endDate = setHoursMinutes(timeRange.endHour ?? 0, timeRange.endMinute ?? 0, true);
      return `${this.getPrefixNumber(startDate.getHours())}:${this.getPrefixNumber(startDate.getMinutes())}
       - ${this.getPrefixNumber(endDate.getHours())}:${this.getPrefixNumber(endDate.getMinutes())}`;
    }
    return '';
  }

  private getPrefixNumber(input: number) {
    return ('0' + input).slice(-2);
  }

  private getThreshold(threshold: number, category: string) {
    if (category.includes(SensorType.Temperature)) {
      if (this.unitSystem === EnvironmentalUnitSystem.IMPERIAL) {
        return `${threshold}${CoreConstants.DegreeFahrenheit}`;
      }
      return `${threshold}${CoreConstants.DegreeCelsius}`;
    }
    return threshold;
  }
}
