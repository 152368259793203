import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from '@dpdhl/iot-shared-ui';
import { ManagementComponent } from './management.component';

const routes: Routes = [
  {
    path: 'manage',
    component: ManagementComponent,
  },
];

@NgModule({
  declarations: [ManagementComponent],
  imports: [RouterModule.forChild(routes), CommonModule, ShellComponent],
  bootstrap: [ManagementComponent],
})
export class ManagementModule {}
