import { Injectable } from '@angular/core';
import {
  ApplicationService,
  DeviceDetails,
  DeviceGroupsTelemetryDownloadRequest,
  DeviceListSensorType,
  FacilityInfo,
  FileDownloadResponse,
  FileType,
  Org,
  SensorType,
  StatusSeveritiesDays,
  SummaryReportDownloadRequest,
  WeatherData,
  WeatherDataRequest,
} from '@dpdhl-iot/api/backend';
import {
  celsiusToFahrenheit,
  CoreConstants,
  entitiesEqual,
  getApplicationConfig,
  removeDuplicateBatterySensorFromArea,
  UserPreferenceDataService,
} from '@dpdhl-iot/shared';
import { ApplicationDataService, EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/management-api';
import { Mutex } from 'async-mutex';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subject,
  tap,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFacilityService {
  private selectedApplication: IotApplicationModel;

  private readonly filteredFacilitiesProvider = new BehaviorSubject<FacilityInfo[]>([]);
  private readonly selectedFacilityProvider = new ReplaySubject<Org>(1);
  private readonly facilityDevicesProvider = new Subject<DeviceDetails[]>();
  private applicationFacilities: FacilityInfo[] = [];
  private prevFilteredFacilities: FacilityInfo[] = [];
  private readonly mutex = new Mutex();
  private refreshFacilityInterval = setInterval.prototype;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedFacility = this.selectedFacilityProvider.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public filteredFacilities = this.filteredFacilitiesProvider.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public facilityDevices = this.facilityDevicesProvider.asObservable();

  constructor(
    private readonly applicationDataService: ApplicationDataService,
    private readonly applicationService: ApplicationService,
    private readonly usersDataService: UserPreferenceDataService,
  ) {
    const appConfig = getApplicationConfig();
    this.applicationDataService.application$
      .pipe(
        filter(
          (a) =>
            !!a.application &&
            appConfig?.applicationGroupId === CoreConstants.COMO_APPLICATION_GROUP_ID &&
            // Prevent if application didn't change
            this.selectedApplication?.id !== a.application?.id,
        ),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((app) => {
        (async () => {
          this.applicationFacilities = [];
          this.setFilteredFacilities(this.applicationFacilities);
          this.selectedApplication = app;
          await this.getApplicationFacilities();
        })();
      });
  }

  async getApplicationFacilities(): Promise<FacilityInfo[]> {
    const release = await this.mutex.acquire();
    try {
      if (this.applicationFacilities.length === 0) {
        this.applicationFacilities = await this.getApplicationDetails();
        this.applicationFacilities.sort((a, b) => a.name.localeCompare(b.name));
      }
      return this.applicationFacilities;
    } finally {
      clearInterval(this.refreshFacilityInterval);
      this.setFilteredFacilities(this.applicationFacilities);
      release();
    }
  }

  async getApplicationDetails(): Promise<FacilityInfo[]> {
    return lastValueFrom(
      this.applicationService.getGlobalDashboardFacilityDetails(
        this.selectedApplication.uuid,
        this.selectedApplication.id,
        CoreConstants.API_VERSION,
      ),
    );
  }

  getDeviceAccessGroupDetails(deviceAccessGroupId: string): Observable<DeviceDetails[]> {
    return combineLatest([
      this.applicationService.getDeviceAccessGroupDetails(
        this.selectedApplication.id,
        this.selectedApplication.uuid,
        deviceAccessGroupId,
        CoreConstants.API_VERSION,
      ),
      this.usersDataService.userPreference$,
    ]).pipe(
      tap((a) =>
        a[0].forEach((deviceDetail) => {
          deviceDetail.deviceSensorTypes?.forEach((sType) => {
            if (
              sType.deviceSensorType === DeviceListSensorType.TEMPERATURE &&
              a[1].unitSystem === EnvironmentalUnitSystem.IMPERIAL
            ) {
              sType.deviceSensorValue = celsiusToFahrenheit(sType.deviceSensorValue);
            }
          });
        }),
      ),
      map((a) => a[0]),
      tap((result) => this.facilityDevicesProvider.next(result)),
    );
  }

  getDeviceDetailsForDeviceAccessGroups(
    deviceAccessGroupIds: string[],
  ): Observable<DeviceDetails[]> {
    return combineLatest([
      this.applicationService.getDeviceDetailsForDeviceAccessGroups(
        this.selectedApplication.id,
        CoreConstants.API_VERSION,
        deviceAccessGroupIds,
      ),
      this.usersDataService.userPreference$,
    ]).pipe(
      tap((a) =>
        a[0].forEach((deviceDetail) => {
          deviceDetail.deviceSensorTypes?.forEach((sType) => {
            if (
              sType.deviceSensorType === DeviceListSensorType.TEMPERATURE &&
              a[1].unitSystem === EnvironmentalUnitSystem.IMPERIAL
            ) {
              sType.deviceSensorValue = celsiusToFahrenheit(sType.deviceSensorValue);
            }
          });
        }),
      ),
      map((a) => a[0]),
    );
  }

  downloadDeviceAccessGroupsTelemetry(
    fileType: FileType,
    request: DeviceGroupsTelemetryDownloadRequest,
  ): Observable<FileDownloadResponse> {
    return this.applicationService.downloadDeviceAccessGroupsTelemetry(
      this.selectedApplication.id,
      fileType,
      CoreConstants.API_VERSION,
      request,
    );
  }

  downloadFacilityDetails(
    facilityId: string,
    fileType: FileType,
    request: SummaryReportDownloadRequest,
  ): Observable<FileDownloadResponse> {
    return this.applicationService.downloadFacilityReport(
      this.selectedApplication.id,
      facilityId,
      fileType,
      CoreConstants.API_VERSION,
      request,
    );
  }

  downloadAreaDetails(
    facilityId: string,
    areaId: string,
    fileType: FileType,
    request: SummaryReportDownloadRequest,
  ): Observable<FileDownloadResponse> {
    return this.applicationService.downloadAreaReport(
      this.selectedApplication.id,
      facilityId,
      areaId,
      fileType,
      CoreConstants.API_VERSION,
      request,
    );
  }

  getFacilityWeatherData(
    facilityId: string,
    from: number,
    until: number,
    sensorTypes: Array<SensorType>,
  ): Observable<WeatherData[]> {
    const request: WeatherDataRequest = {
      from,
      until,
      sensorTypes,
    };

    return this.applicationService.getFacilityWeatherData(
      this.selectedApplication.id,
      facilityId,
      CoreConstants.API_VERSION,
      request,
    );
  }

  getFacilityAlertMetrics(facilityId: string, statusSeveritiesDays: StatusSeveritiesDays[]) {
    return this.applicationService.getFacilityAlertMetrics(
      this.selectedApplication.id,
      facilityId,
      CoreConstants.API_VERSION,
      statusSeveritiesDays,
    );
  }

  async getApplicationFacilityDetails(facilityId: string): Promise<Org> {
    return lastValueFrom(
      this.applicationService.getApplicationFacilityDetails(
        this.selectedApplication.uuid,
        this.selectedApplication.id,
        facilityId,
        CoreConstants.API_VERSION,
      ),
    );
  }

  getFacilityDetails(facilityId: string) {
    return this.applicationService.getApplicationFacilityDetails(
      this.selectedApplication.uuid,
      this.selectedApplication.id,
      facilityId,
      CoreConstants.API_VERSION,
    );
  }

  async setSelectedFacility(facilityId: string): Promise<Org> {
    const facility = await this.getApplicationFacilityDetails(facilityId);
    if (facility) {
      facility.subOrgs.forEach((a) => removeDuplicateBatterySensorFromArea(a));
      this.selectedFacilityProvider.next(facility);
      this.updateApplicationFacility(facility);
      this.refreshApplicationFacility(facility);
    }
    return facility;
  }

  setFilteredFacilities(filteredFacilities: FacilityInfo[]) {
    if (!entitiesEqual(filteredFacilities, this.prevFilteredFacilities)) {
      this.filteredFacilitiesProvider.next(filteredFacilities);
      this.prevFilteredFacilities = filteredFacilities;
    }
  }

  private updateApplicationFacility(facility: Org) {
    const facilityIndex = this.applicationFacilities.find((x) => x.id === facility.id);
    if (facilityIndex) {
      facilityIndex.hasOpenAlerts = facility.totalOpenAlertsCount > 0;
      facilityIndex.hasAcceptedAlerts = facility.totalAcceptedAlertsCount > 0;
      facilityIndex.hasResolvingAlerts = facility.totalResolvingAlertsCount > 0;
      facilityIndex.totalActiveDevices = facility.totalActiveDeviceCount;
      facilityIndex.totalInActiveDevices = facility.totalInActiveDeviceCount;
      facilityIndex.totalDevices = facility.totalDeviceCount;
    }
  }

  private refreshApplicationFacility(facility: Org) {
    clearInterval(this.refreshFacilityInterval);
    this.refreshFacilityInterval = setInterval(() => {
      (async () => {
        await this.setSelectedFacility(facility.id);
      })();
    }, 60000);
  }
}
