import { Pipe, PipeTransform } from '@angular/core';
import { AuditInformationAbstraction } from '@dpdhl-iot/api/management';
import { DPDHLTable } from '@dpdhl-iot/como-ui';
import {
  numericComparer,
  textComparer,
  timeStampComparer,
} from '../global-functions/global.function';

const numberSortingConfig: DPDHLTable.ColumnSortingConfig = {
  sort: numericComparer,
};

const stringSortingConfig: DPDHLTable.ColumnSortingConfig = {
  sort: textComparer,
};

const timestampSortingConfig: DPDHLTable.ColumnSortingConfig = {
  sort: timeStampComparer,
};

const auditInformationModifiedOnSortingConfig: DPDHLTable.ColumnSortingConfig = {
  sort: (a?: AuditInformationAbstraction, b?: AuditInformationAbstraction) =>
    timeStampComparer(a?.modifiedOn, b?.modifiedOn),
};

const auditInformationCreatedBySortingConfig: DPDHLTable.ColumnSortingConfig = {
  sort: (a?: AuditInformationAbstraction, b?: AuditInformationAbstraction) =>
    textComparer(a?.createdBy, b?.createdBy),
};

enum SortType {
  Numeric = 'Numeric',
  Text = 'Text',
  Timestamp = 'Timestamp',
  AuditModifiedOn = 'AuditModifiedOn',
  AuditCreatedBy = 'AuditCreatedBy',
}

@Pipe({ name: 'sortingConfig' })
export class SortingConfigPipe implements PipeTransform {
  transform(value: string): DPDHLTable.ColumnSortingConfig | null {
    switch (value) {
      case SortType.Text:
        return stringSortingConfig;
      case SortType.Numeric:
        return numberSortingConfig;
      case SortType.Timestamp:
        return timestampSortingConfig;
      case SortType.AuditModifiedOn:
        return auditInformationModifiedOnSortingConfig;
      case SortType.AuditCreatedBy:
        return auditInformationCreatedBySortingConfig;
    }
    return null;
  }
}
