import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AlertDataService,
  AlertDownloadService,
  getAlertStatusFiltersByTab,
} from '@dpdhl-iot/alert';
import { FacilityInfo, FileType } from '@dpdhl-iot/api/backend';
import {
  AlertActionModel,
  AlertApplicationType,
  AlertViewModel,
  ApplicationFacilityService,
  DefaultViewConfiguration,
  getSelectableAndWorkflowEnabledFacilities,
  InventoryTrackingFacilityService,
  UpdateAlertStatusRequestArgs,
  ViewConfigurationModel,
  ViewConfigurationService,
} from '@dpdhl-iot/shared';
import { NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { from, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
})
export class AlertListComponent implements OnInit, OnDestroy {
  @Input() showDownLoadAlerts = true;
  @Input() helpText = 'alert.containerInfo';

  alertApplicationType = AlertApplicationType.ConditionMonitoring;
  viewConfiguration: ViewConfigurationModel = DefaultViewConfiguration;

  isPageLoading = true;
  isGridLoading = true;

  alertData: AlertViewModel[] = [];
  workFlowFacilities: string[] = [];
  selectableFacilities: string[] = [];
  hasAlertUpdatePermissionFacilities: string[] = [];

  downloadTypes: string[] = [FileType.EXCEL, FileType.PDF];
  minDate = new Date(new Date().setHours(0, 0, 0, 0));
  maxDate = new Date();

  unResolvedAlertsCount = 0;
  resolvedAlertsCount = 0;
  totalAlertsCount = 0;

  isDownloading = false;
  selectedTab = 0;

  fetchAlertsSubscription?: Subscription = undefined;

  downloadForm = new FormGroup({
    downloadType: new FormControl(FileType.EXCEL),
    dateRange: new FormControl(this.getInitialDateRange()),
  });

  alertAction: AlertActionModel | undefined = undefined;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly notificationDataService: NotificationDataService,
    private readonly viewConfigurationService: ViewConfigurationService,
    private readonly alertDataService: AlertDataService,
    private readonly comoFacilityService: ApplicationFacilityService,
    private readonly inventoryFacilityService: InventoryTrackingFacilityService,
    private readonly alertDownloadService: AlertDownloadService,
  ) {}

  ngOnInit(): void {
    this.selectedTab = +(this.route.snapshot.queryParamMap.get('tab') ?? 0);
    this.subscriptions.push(
      this.viewConfigurationService.currentViewConfiguration$.subscribe((viewConfiguration) => {
        this.alertApplicationType = viewConfiguration.inventoryTracking
          ? AlertApplicationType.InventoryTracking
          : AlertApplicationType.ConditionMonitoring;
        this.viewConfiguration = viewConfiguration;
        this.fetchData();
      }),
    );
  }

  downloadAlertList(): void {
    const downloadType = this.downloadForm.value.downloadType;
    const dateRange = this.downloadForm.value.dateRange;
    if (this.isDownloading || !dateRange || !downloadType) {
      return;
    }
    this.isDownloading = true;
    this.subscriptions.push(
      this.alertDownloadService.downloadAlerts(
        dateRange,
        [],
        this.selectedTab,
        downloadType,
        () => {
          this.isDownloading = false;
        },
      ),
    );
  }

  fetchData(): void {
    let getAppFacilities$: Observable<FacilityInfo[]>;
    switch (this.alertApplicationType) {
      case AlertApplicationType.ConditionMonitoring:
        getAppFacilities$ = from(this.comoFacilityService.getApplicationFacilities());
        break;
      case AlertApplicationType.InventoryTracking:
        getAppFacilities$ = this.inventoryFacilityService.getInventoryTrackingDashboard();
        break;
    }
    this.subscriptions.push(
      getAppFacilities$.subscribe((result) => {
        if (result.length > 0) {
          const facilityGroups = getSelectableAndWorkflowEnabledFacilities(result);
          this.workFlowFacilities = facilityGroups.workflowEnabled;
          this.selectableFacilities = facilityGroups.selectable;
          this.hasAlertUpdatePermissionFacilities = facilityGroups.hasAlertUpdatePermission;
          this.calculateAlertsCount();
          this.fetchAlerts();
        }
      }),
    );
  }

  fetchAlerts(): void {
    this.isPageLoading = true;
    this.isGridLoading = true;
    this.alertData = [];
    if (this.fetchAlertsSubscription) {
      this.fetchAlertsSubscription.unsubscribe();
      this.fetchAlertsSubscription = undefined;
    }

    this.fetchAlertsSubscription = this.alertDataService
      .fetchAlerts(
        this.viewConfiguration.alert.removeValuePrefixes,
        null,
        getAlertStatusFiltersByTab(this.selectedTab),
        null,
      )
      .subscribe((alerts) => {
        this.fetchAlertsSubscription = undefined;
        this.alertData = [...this.alertData, ...alerts];
        this.isPageLoading = false;
        this.isGridLoading = false;
      });
  }

  alertTabSelected(tabIndex: number): void {
    if (this.selectedTab === tabIndex) {
      return;
    }
    this.selectedTab = tabIndex;
    this.fetchAlerts();
  }

  calculateAlertsCount(): void {
    this.subscriptions.push(
      this.alertDataService.calculateAlertsCount().subscribe(([unresolved, resolved]) => {
        this.unResolvedAlertsCount = unresolved;
        this.resolvedAlertsCount = resolved;
        this.totalAlertsCount = unresolved + resolved;
      }),
    );
  }

  onSelectAlertActionRow(alertAction: AlertActionModel) {
    if (!this.alertDataService.shouldRefreshRemarks(alertAction.alert)) {
      this.alertAction = alertAction;
      return;
    }
    this.subscriptions.push(
      this.alertDataService.getAlertRemarks(alertAction.alert.id).subscribe((result) => {
        alertAction.alert.alertRemarks = result;
        this.alertAction = alertAction;
      }),
    );
  }

  onUpdateAlertStatus(args: UpdateAlertStatusRequestArgs): void {
    this.subscriptions.push(
      this.alertDataService.updateAlertStatus(args.alert, args.request).subscribe((result) => {
        this.alertData = [result, ...this.alertData.filter((alert) => alert.id !== result.id)];
        this.notificationDataService.addNotification({
          text: this.translateService.instant(
            'dashboard.alert.context.' + args.action.toLowerCase(),
          ),
          type: NotificationType.INFO,
        });
      }),
    );
  }

  onCloseAlertStatus() {
    this.alertAction = undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    if (this.fetchAlertsSubscription) {
      this.fetchAlertsSubscription.unsubscribe();
      this.fetchAlertsSubscription = undefined;
    }
  }

  private getInitialDateRange(startDays = -180, endDays = 0): [Date, Date] {
    this.minDate = DateTime.local().startOf('day').plus({ days: startDays }).toJSDate();
    this.maxDate = DateTime.local().endOf('day').plus({ days: endDays }).toJSDate();
    return [this.minDate, this.maxDate];
  }
}
