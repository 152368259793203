import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  DatePickerModule,
  InputModule,
  LoadingModule,
  ModalModule,
  PopoverContainerComponent,
  PopoverDirective,
  SelectModule,
  SliderModule,
  SvgIconModule,
  TabsModule,
} from '@dpdhl/angular-shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BaseChartDirective } from 'ng2-charts';
import { CarouselOptionDirective } from '../../../shared/src/lib/components/carousel/carousel-option/carousel-option.directive';
import { CarouselComponent } from '../../../shared/src/lib/components/carousel/carousel.component';
import { BeltLengthChartComponent } from './belt-length-chart/belt-length-chart';
import { ConditionOverviewComponent } from './condition-overview/condition-overview.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { ExtendedFrequencyDiagramComponent } from './extended-frequency-diagram/extended-frequency-diagram.component';
import { FrequencyAnalysisCellComponent } from './frequency-analysis-cell/frequency-analysis-cell.component';
import { FrequencyAnalysisComponent } from './frequency-analysis/frequency-analysis.component';
import { FrequencyAvailabilityComponent } from './frequency-availability/frequency-availability.component';
import { FrequencyDiagramComponent } from './frequency-diagram/frequency-diagram.component';
import { NoiseLevelComponent } from './noise-level/noise-level.component';
import { OverviewComponent } from './overview/overview.component';
import { SensorSettingsComponent } from './sensor-settings/sensor-settings.component';
import { SorterViewComponent } from './sorter-view/sorter-view.component';
import { VolumeLevelChartComponent } from './volume-level-chart/volume-level-chart.component';

@NgModule({
  declarations: [
    OverviewComponent,
    VolumeLevelChartComponent,
    FrequencyAnalysisComponent,
    FrequencyAnalysisCellComponent,
    ExtendedFrequencyDiagramComponent,
    SensorSettingsComponent,
    DeviceDetailComponent,
    BeltLengthChartComponent,
    SorterViewComponent,
    ConditionOverviewComponent,
    FrequencyAvailabilityComponent,
    FrequencyDiagramComponent,
    NoiseLevelComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DatePickerModule,
    ReactiveFormsModule,
    InputModule,
    TabsModule,
    SelectModule,
    LoadingModule,
    CardModule,
    ButtonModule,
    ModalModule,
    AngularSvgIconModule,
    SliderModule,
    SvgIconModule,
    RouterLink,
    PopoverDirective,
    PopoverContainerComponent,
    CarouselComponent,
    CarouselOptionDirective,
    BaseChartDirective,
    TranslocoModule,
  ],
  exports: [
    DeviceDetailComponent,
    OverviewComponent,
    VolumeLevelChartComponent,
    FrequencyAnalysisComponent,
    BeltLengthChartComponent,
    FrequencyAnalysisCellComponent,
    SensorSettingsComponent,
    SorterViewComponent,
    ConditionOverviewComponent,
  ],
})
export class PredictiveMaintenanceModule {}
