import { Injectable } from '@angular/core';
import {
  AdminPermission,
  AirQualityLink,
  ApplicationDeviceFilter,
  ApplicationDevices,
  ApplicationDevicesRequest,
  DeviceAssociateModel,
  DeviceAssociateResponse,
  DeviceDissociateResponse,
  DeviceManagementService,
  DeviceMetadataModel,
  DeviceMetadataUpdateModel,
  DeviceModel,
  DeviceUpdateModel,
  DeviceUpsertModel,
  DeviceUpsertResponse,
  FileDownloadResponse,
  FileType,
  InventoryTrackingService,
} from '@dpdhl-iot/api/backend';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/management-api';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CoreConstants } from '../../constants/core-constants';

@Injectable({
  providedIn: 'root',
})
export class CoMoDeviceManagementService {
  private selectedApplication: IotApplicationModel;

  constructor(
    private readonly deviceManagementService: DeviceManagementService,
    private readonly inventoryApplicationService: InventoryTrackingService,
    private readonly selectedApplicationService: ApplicationDataService,
  ) {
    this.selectedApplicationService.application$
      .pipe(
        filter((a) => !!a.application),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((result) => {
        this.selectedApplication = result;
      });
  }

  static getDevicesDetailsRequest(
    includeDeviceLink = false,
    deviceFilter = ApplicationDeviceFilter.ALL,
  ) {
    const devicesDetailsRequest: ApplicationDevicesRequest = {
      includeDeviceLink,
      deviceFilter,
    };
    return devicesDetailsRequest;
  }

  getDeviceDetails(request: ApplicationDevicesRequest): Observable<Array<ApplicationDevices>> {
    return this.deviceManagementService.getDeviceDetails(
      this.selectedApplication.id,
      this.selectedApplication.uuid,
      CoreConstants.API_VERSION,
      request,
    );
  }

  getInventoryDeviceDetails(): Observable<Array<ApplicationDevices>> {
    return this.inventoryApplicationService.getDevices(
      this.selectedApplication.id,
      this.selectedApplication.uuid,
      CoreConstants.API_VERSION,
    );
  }

  getAllDevices(
    includeDeviceLink = false,
    isInventoryTracking = false,
  ): Observable<Array<ApplicationDevices>> {
    return isInventoryTracking
      ? this.getInventoryDeviceDetails()
      : this.getDeviceDetails(
          CoMoDeviceManagementService.getDevicesDetailsRequest(includeDeviceLink),
        );
  }

  downloadDeviceDetails(
    fileType: FileType,
    request: ApplicationDevicesRequest,
  ): Observable<FileDownloadResponse> {
    return this.deviceManagementService.downloadDeviceDetails(
      this.selectedApplication.id,
      this.selectedApplication.uuid,
      fileType,
      CoreConstants.API_VERSION,
      request,
    );
  }

  downloadAllDeviceDetails(
    fileType: FileType,
    deviceFilter = ApplicationDeviceFilter.ALL,
    includeDeviceLink = false,
  ): Observable<FileDownloadResponse> {
    return this.downloadDeviceDetails(
      fileType,
      CoMoDeviceManagementService.getDevicesDetailsRequest(includeDeviceLink, deviceFilter),
    );
  }

  getAirQualityLink(deviceId: string): Observable<AirQualityLink> {
    return this.deviceManagementService.getAirQualityLink(deviceId, CoreConstants.API_VERSION);
  }

  getQRCodeImage(deviceId: string): Observable<string> {
    return this.deviceManagementService.getQrCode(deviceId, CoreConstants.API_VERSION);
  }

  updateDeviceMetadataByDeviceId(
    deviceId: string,
    request: DeviceMetadataUpdateModel,
  ): Observable<DeviceMetadataModel> {
    return this.deviceManagementService.updateDeviceMetadataByDeviceId(
      this.selectedApplication.id,
      this.selectedApplication.uuid,
      deviceId,
      CoreConstants.API_VERSION,
      request,
    );
  }

  updateDeviceArea(deviceId: string, areaId: string): Observable<DeviceModel> {
    const request: DeviceUpdateModel = {
      areaId,
    };
    return this.deviceManagementService.updateDevice(
      this.selectedApplication.id,
      this.selectedApplication.uuid,
      deviceId,
      CoreConstants.API_VERSION,
      request,
    );
  }

  upsertDevices(
    deviceUpsertModels: DeviceUpsertModel[],
    dryRun: boolean,
  ): Observable<DeviceUpsertResponse> {
    return this.deviceManagementService.upsertDevices(
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
      dryRun,
      deviceUpsertModels,
    );
  }

  associateDevices(
    deviceAssociateModels: DeviceAssociateModel[],
    dryRun: boolean,
  ): Observable<DeviceAssociateResponse> {
    return this.deviceManagementService.associateDevices(
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
      dryRun,
      deviceAssociateModels,
    );
  }

  disassociateDevices(devices: string[], dryRun: boolean): Observable<DeviceDissociateResponse> {
    return this.deviceManagementService.dissociateDevices(
      CoreConstants.API_VERSION,
      dryRun,
      devices,
    );
  }

  hasDeviceManagerPermission(): Observable<AdminPermission> {
    return this.deviceManagementService.hasDeviceManagerPermission(
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
    );
  }
}
