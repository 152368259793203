import { AreaAlertMetrics, DeviceDetails } from '@dpdhl-iot/api/backend';
import { Sensor } from '@dpdhl-iot/api/prognosis';

export interface DeviceSensorData {
  levelName: string;
  volumes: SensorData[];
  averages: { day: Date; avg: number }[];
}

export interface SensorData {
  date: Date;
  value: number | null;
}

export interface DeviceDetailsPredictiveMaintenance extends DeviceDetails {
  sensor: Sensor;
  normalCells: number;
  circulations?: number;
  alerts: AlertDetail[];
  alertCells: DeviceDetailsAlert[];
}

export interface DeviceDetailsAlert {
  cell: number;
  createDate: Date;
  alertState: CellAlertSeverity;
  alertId: string;
}

export interface DeviceCountMapping {
  alertCount: number;
  warningCount: number;
  normalCount: number;
  isActive: boolean;
  alerts: AlertDetail[];
}

export interface AlertDetail {
  alertType: string;
  alertDate: number;
}

export interface AreaAlertSensorMetrics extends AreaAlertMetrics {
  devices: {
    id: string;
    name?: string;
    deviceAccessGroupId?: string;
  }[];
  totalCells: number;
  hasMissingParameters: boolean;
}

export enum CellAlertSeverity {
  ALERT,
  WARNING,
}

export enum SensorAlertType {
  SensorActiveAlert,
  SensorCirculationAlert,
  SensorMicAlert,
}

export enum CellAlertStatus {
  NewAlert,
  NewWarning,
  SeverityToAlert,
  SeverityToWarning,
  ManuallyResolved,
  AutomaticallyResolved,
}
